//////////////// Icons ////////////////

.buy-report-cell h2, .buy-score-cell h2 {
    background-position-x: -140px;
    background-size: 30%;
}

#upsellcontainer #upsellsides #upsellleft .upsellleft-sub-title {
    background-position-x: -50px;
    background-size: 20%;
}

.no-svg .header__menu-button {
    background: no-repeat center center fixed 0;  /*IE8 HACK*/
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/tiled_company.png', sizingMethod='scale');
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/tiled_company.png',sizingMethod='scale')";
}

.no-svg .header__logo a {
    background: no-repeat center center fixed 0;  /*IE8 HACK*/
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/CoCheck-Logo.png', sizingMethod='scale');
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/CoCheck-Logo.png',sizingMethod='scale')";
}

.no-svg .header__logo .logo {
    width: 130px;
    height: 30px;
    padding-left: 0px;
}

.no-svg .search-button {
    background: no-repeat center center fixed 0;  /*IE8 HACK*/
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/search_button.png',sizingMethod='scale')";
}

//////////////// Backgrounds ////////////////

.top-banner {
    height: 500px;
}

.no-multiplebgs .claim-banner {
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#EFF2F5', endColorstr='#EFF2F5');
}

.overlay--box:hover .overlay {
    filter: alpha(opacity=10);
}

//////////////// Layout ////////////////

.transformer.is-open {
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1)";

    margin-left: 260px;
    margin-top: -3px;
}

//////////////// Home page ////////////////

.recent-searches-banner__header {
    text-align: center;
}

.recent-searches-banner__list {

    text-align: center;

    a {
        color: #323d4d;
        line-height: 30px;
        font-size: 19px;
    }

    li {
        display: inline;
        white-space: nowrap;
    }

    li + li {
        &:before {
            content: "|";
            color: #a6b5cd;
            margin: 0px 10px;
        }
    }
}

//////////////// Footer ////////////////

.no-csscolumns .fallback-col
{
    width: 30%;
    float: left;
    padding-right: 2%;
}

//////////////// SVG/PNG Fixes ////////////////

.pages-header--mobile .pages-header__thumbnail {
    background-image:url('/images/company/company_thumbnail.png');
}

.pages-header__logo .verified-container img {
    height:100% !important;
    width:100% !important;
}
